
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import "@babel/polyfill";
import "jquery";
import { Utils } from './scripts/utils/utils';
import "./css/index.scss";

export class abFramework {

    public static util;

    constructor() {
        this.init();
    }

    private init() {
        abFramework.util = new Utils();
        $(() => {
            // Utils.elementLoaded(".panel-body", (elem) => {
            //     console.log("teste======", $(elem).html());
            //     //Utils.Teste();
            // });
        
            
        
            document.addSelectorListener(".panel-body", (elem) => {
                $(".panel-body").height($(".panel-body").closest(".panel-fixo").height() - 30);
                $(".pdf-view .panel-body").height($(".panel-body").closest(".panel-fixo").height());
                $(".panel-fixo .nav").height($(".panel-fixo .nav").closest(".panel-fixo").height() - 30);
                
            });
            document.addSelectorListener("#modalTypeForms", (elem) => {
                //util.customSelect();
            });
        
            $(window).resize(function () {
                $(".panel-body").height($(".panel-body").closest(".panel-fixo").height() - 30);
                $(".pdf-view .panel-body").height($(".panel-body").closest(".panel-fixo").height());
                $(".panel-fixo .nav").height($(".panel-fixo .nav").closest(".panel-fixo").height() - 30);
            })
        
            // $("body").on('DOMSubtreeModified', function (e) {
            //     console.log("panel =====", $(".panel-body")[0]);
            //     if (e.target.matches(".panel-body"))
            //         console.log("entrou1");
            // });
        
            // $(document).on('DOMNodeInserted', ".page-content .panel-fixo", function (e) {
            //     console.log(e.target.matches(".page-body"));
            //     if (e.target.matches(".panel-body"))
            //         console.log("entrou2");
            // });
        });
    }

}

new abFramework();

